import React from 'react';
import MatriculaInput from './form-elements/Matricula';

class Matricula extends React.Component {
  state = {
    form: { matriculaFuncional: '' }
  }

  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;

    await this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })

    this.props.handleFormChange(
      { valid: this.valid() },
      {
        matriculaFuncional: this.state.form.matriculaFuncional
      }
    );
  }

  valid = () => {
    return true;
  }

  render() {
    return (
      <MatriculaInput
        tituloMatricula={this.props.tituloMatricula}
        value={this.state.form.matriculaFuncional}
        onChange={this.handleChange}
        ref={ref => this.matriculaFuncional = ref}
        required
      />
    );
  }
}

export default Matricula;
