import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import PropTypes from 'prop-types';

class Matricula extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    tituloMatricula: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    invalid: false,
    valid: false
  }

  handleChange = (event) => {
    const digitado = event.target.value;
    this.props.onChange(event)

    const valido = this.valid(digitado);
    this.setState({
      invalid: !valido,
      valid: valido,
    });
  }

  valid = value => {
    value = value ?? this.props.value;

    if (this.props.required && ! value) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <FormGroup controlId="formMatricula">
        <FormLabel>{this.props.tituloMatricula}</FormLabel>
        <FormControl
          autoComplete="off"
          onChange={this.handleChange}
          value={this.props.value}
          isInvalid={this.state.invalid}
          isValid={this.state.valid}
          size="lg"
          name="matriculaFuncional"
          required={this.props.required}
          placeholder={this.props.tituloMatricula}
        />
      </FormGroup>
    );
  }
}

export default Matricula;
