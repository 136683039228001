import React from 'react';
import Cpf from './form-elements/Cpf';
import Matricula from './form-elements/Matricula';

class CpfMatricula extends React.Component {
  cpf = null;
  state = {
    form: { cpf: '', matriculaFuncional: '' }
  }

  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    await this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })

    this.props.handleFormChange(
      { valid: this.valid() },
      {
        cpf: this.state.form.cpf && this.state.form.cpf.substr(0, 3) + this.state.form.cpf.substr(4, 3) + this.state.form.cpf.substr(8, 3) + this.state.form.cpf.substr(12, 2),
        matriculaFuncional: this.state.form.matriculaFuncional
      }
    );
  }

  valid = () => {
    return (this.cpf.valid());
  }

  render() {
    return (
      <>
        <Cpf
          value={this.state.form.cpf}
          onChange={this.handleChange}
          ref={(ref) => this.cpf = ref}
          required
        />

        <Matricula
          tituloMatricula={this.props.tituloMatricula}
          value={this.state.form.matriculaFuncional}
          onChange={this.handleChange}
          ref={ref => this.matriculaFuncional = ref}
          required
        />
      </>
    );
  }
}

export default CpfMatricula;
