import React from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import CpfDataNascimento from './CpfDataNascimento';
import CpfDataNascimento2fa from './CpfDataNascimento2fa';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Modal from 'react-bootstrap/Modal';
import { loginUser, logoutUser } from '../actions';

import Button from 'react-bootstrap/Button';
import ParticipanteForm from '../components/ParticipanteForm';
import { bindActionCreators } from 'redux';
import { getBase } from '../components/RouterPanagora';
import CpfMatricula from './CpfMatricula';
import MatriculaDataNascimento from './MatriculaDataNascimento';
import votante from '../fetchs/votante';
import LinkUnico from './LinkUnico';
import { withRouter } from 'react-router-dom';
import { CpfMatricula2fa } from './CpfMatricula2fa';
import EventoInativoPainel from './EventoInativoPainel';
import { DigitaAprovacaoTokenModal } from '../components/DigitaAprovacaoTokenModal';

import { Redirect as RedirectPanagora } from '../components/RouterPanagora';
import { MatriculaDataNascimento2fa } from './MatriculaDataNascimento2fa';
import urna from '../fetchs/urna';
import CpfDataNascimentoMatriculaDoisADois from './CpfDataNascimentoMatriculaDoisADois';
import { CpfDataNascimentoMatriculaDoisADois2fa } from './CpfDataNascimentoMatriculaDoisADois2fa';
import { Cpf2fa } from './Cpf2fa';
import Cpf from './Cpf';
import Questionario from './questionario';

import parse from 'html-react-parser';
import ApenasIntegracao from './ApenasIntegracao';
import { Matricula2fa } from './Matricula2fa';
import CpfDataNascimentoMatricula from './CpfDataNascimentoMatricula';

export const TIPO_AUTENTICACAO_LINK = 1;
export const TIPO_AUTENTICACAO_VALIDA_CPF_DATA_NASCIMENTO = 2;
export const TIPO_AUTENTICACAO_VALIDA_CPF_DATA_NASCIMENTO_MATRICULA = 3;
export const TIPO_AUTENTICACAO_VALIDA_MATRICULA_DATA_NASCIMENTO = 4;
export const TIPO_AUTENTICACAO_VALIDA_SENHA_UNICA = 5;
export const TIPO_AUTENTICACAO_CADASTRO_SEM_APROVACAO = 6;
export const TIPO_AUTENTICACAO_CADASTRO_COM_APROVACAO = 7;
export const TIPO_AUTENTICACAO_VALIDA_CPF_DATA_NASCIMENTO_SENHA_ENVIADA = 8;
export const TIPO_AUTENTICACAO_VALIDA_CPF_MATRICULA = 9;
export const TIPO_AUTENTICACAO_2FA_VALIDA_CPF_DATA_NASCIMENTO = 10;
export const TIPO_AUTENTICACAO_MESARIO_ELETRONICO = 11;
export const TIPO_AUTENTICACAO_2FA_VALIDA_CPF_MATRICULA = 12;
export const TIPO_AUTENTICACAO_2FA_VALIDA_MATRICULA_DATA_NASCIMENTO = 13;
export const TIPO_AUTENTICACAO_VALIDA_NASCIMENTO_CPF_MATRICULA_DOIS_A_DOIS = 14;
export const TIPO_AUTENTICACAO_2FA_VALIDA_NASCIMENTO_CPF_MATRICULA_DOIS_A_DOIS = 15;
export const TIPO_AUTENTICACAO_2FA_VALIDA_CPF = 16;
export const TIPO_AUTENTICACAO_VALIDA_CPF_QUESTIONARIO = 17;
export const TIPO_AUTENTICACAO_APENAS_INTEGRACAO = 18;
export const TIPO_AUTENTICACAO_2FA_VALIDA_MATRICULA = 19;


export const MOTIVO_2FA = '2fa';
export const MOTIVO_NAO_ECONTRADO = 'nao_encontrado';
export const MOTIVO_NAO_APROVADO_SMS = 'nao_aprovado_sms';
export const MOTIVO_NAO_APROVADO = 'nao_aprovado';
export const MOTIVO_EVENTO_FECHADO = 'evento_fechado';
export const MOTIVO_LINK_EXPIRADO = 'link_expirado';
export const MOTIVO_QUESTIONARIO = 'questionario';




function PerguntaNaoEncontradoModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Atenção
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.texto && parse(props.texto)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">Não</Button>
        <Button onClick={props.onSimClick} variant="success">Sim</Button>
      </Modal.Footer>
    </Modal>
  );
}


class FormIncluirParticipanteModal extends React.Component {

  state = {
    abreDigitaToken: false,
    votanteId: null,
    loading: false,
    urnas: []
  }

  componentDidMount() {
    this.fetUrnas();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.evento.id !== this.props.evento.id) {
      this.fetUrnas();
    }
  }

  fetUrnas = () => {
    if (!this.props.evento.id) {
      return;
    }
    urna.fetchList(this.props.evento.id,'inclusao').then(resposta => {
      if (resposta.isSuccess()) {
        this.setState({ urnas: resposta.getData() });
      } else {
        alert('Erro ao buscar urnas. Tente novamente mais tarde.');
        console.error(resposta);
      }
    });
  }

  handleParticipanteInserted = (participante) => {
    this.props.onHide();
    if (participante.aprovado) {
      this.props.onLogin(participante.nome, participante.tokenTemporario);
    } else {
      if (participante.motivo === 'sms') {
        this.abreDigitaTokenAprovacao(participante.id);
      } else {
        //fazer um form bonito para visar q ele nao esta aprovado
      }

    }
  }

  abreDigitaTokenAprovacao = (votanteId) => {
    this.setState({ abreDigitaToken: true, votanteId: votanteId });
  }

  fechaDigitaTokenAprovacao = () => {
    this.setState({ abreDigitaToken: false });
  }
  handleLoadingChange = (loading) => {
    this.setState({ loading: loading });
  }

  render() {
    return (
      <>
        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          size="lg"
          className="modal-dialog-scrollable"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Cadastro
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ParticipanteForm
              formName="participanteForm"
              onParticipanteInserted={this.handleParticipanteInserted}
              evento={this.props.evento}
              postParticipante={(params) => votante.post(this.props.evento.id, params)}
              onLoadingChange={this.handleLoadingChange}
              urnas={this.state.urnas}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide} variant="secondary">Cancelar</Button>
            <Button type="submit" disabled={this.state.loading} form="participanteForm" variant="success">
              {this.state.loading ? "Salvando..." : "Salvar"}
            </Button>
          </Modal.Footer>
        </Modal>
        <DigitaAprovacaoTokenModal
          isOpen={this.state.abreDigitaToken}
          onHide={this.fechaDigitaTokenAprovacao}
          votanteId={this.state.votanteId}
          evento={this.props.evento}
          login={this.props.onLogin}
          incluirParticipanteHandle={this.props.incluirParticipanteHandle}
        />
      </>
    );
  }
}


export class Auth extends React.Component {

  state = {
    perguntaNaoEncontradoShow: false,
    formIncluirParticipanteShow: false,
    textoPergunta: null,
    redirectPanagora: null,
    redirectWithBase: null
  };

  redirectAuth = null;

  componentDidMount() {
    this.props.logoutUser();
  }

  handleNaoEncontrado = ({ redirect }) => {
    this.redirectAuth = redirect;
    if (redirect) {
      const texto = 'Não foi possível se autenticar com os dados informados, gostaria de ser encaminhado para continuar o processo?';
      this.setState(
        {
          perguntaNaoEncontradoShow: true,
          textoPergunta: texto
        });
    }
    else if (this.props.evento.item.podeVotarSeparado) {
      this.setState({
        perguntaNaoEncontradoShow: true,
        textoPergunta: this.props.evento.item.textoVotoSeparadoHtml
      });
    } else if (this.props.evento.item.podeCadastrarParticipante) {
      const texto = 'Você não foi encontrado como participante apto para participar do evento, gostaria de se cadastrar?';
      this.setState(
        {
          perguntaNaoEncontradoShow: true,
          textoPergunta: texto
        });
    }
  }

  openIncluirParticipante = () => {
    if (this.props.evento.item.podeCadastrarParticipante) {
      this.setState({ redirectPanagora: '/inscricao' });
    } else {
      this.setState({
        formIncluirParticipanteShow: true,
      });
    }
  }

  handleSimPerguntaNaoEncontrado = () => {
    this.setState({
      perguntaNaoEncontradoShow: false
    });
    if (this.redirectAuth) {
      location.href = this.redirectAuth;
    } else {
      this.openIncluirParticipante();
    }
  }

  handlePerguntaNaoEncontradoClose = () => {
    this.setState({ perguntaNaoEncontradoShow: false });
  }

  handleIncluirParticipanteClose = () => {
    this.setState({
      formIncluirParticipanteShow: false
    });
  }

  login = async (nome, tokenTemporario) => {
    const locationHelper = locationHelperBuilder({});
    await this.props.loginUser(nome, tokenTemporario);
    let redirectQueryParam = locationHelper.getRedirectQueryParam(this.props);
    if (redirectQueryParam) {
      if (redirectQueryParam.includes('/auth')) {
        redirectQueryParam = null;
      }
    }
    const redirect = redirectQueryParam ||
      getBase() ||
      '/';
    this.setState({ redirectWithBase: redirect })
  }

  getTipoAutenticacaoForm = () => {
    const {
      evento,
      className
    } = this.props;

    let tipoAutenticacao = null;

    switch (evento.item.tipoAutenticacaoId) {
      case TIPO_AUTENTICACAO_VALIDA_CPF_DATA_NASCIMENTO:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={comp => (<CpfDataNascimento handleFormChange={comp} />)}
          />
        )
        break;
      case TIPO_AUTENTICACAO_VALIDA_CPF_DATA_NASCIMENTO_MATRICULA:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={comp => (
              <CpfDataNascimentoMatricula
                handleFormChange={comp}
                tituloMatricula={evento.item.tituloMatricula}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_VALIDA_MATRICULA_DATA_NASCIMENTO:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={comp => (
              <MatriculaDataNascimento
                tituloMatricula={evento.item.tituloMatricula}
                handleFormChange={comp}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_VALIDA_CPF_MATRICULA:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={comp => (<CpfMatricula tituloMatricula={evento.item.tituloMatricula} handleFormChange={comp} />)}
          />
        )
        break;
      case TIPO_AUTENTICACAO_APENAS_INTEGRACAO:
        tipoAutenticacao = <ApenasIntegracao />
        break;
      case TIPO_AUTENTICACAO_VALIDA_NASCIMENTO_CPF_MATRICULA_DOIS_A_DOIS:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={comp => (<CpfDataNascimentoMatriculaDoisADois tituloMatricula={evento.item.tituloMatricula} handleFormChange={comp} />)}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_NASCIMENTO_CPF_MATRICULA_DOIS_A_DOIS:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <CpfDataNascimentoMatriculaDoisADois2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                tituloMatricula={evento.item.tituloMatricula}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_CPF_DATA_NASCIMENTO:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <CpfDataNascimento2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_CPF_MATRICULA:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <CpfMatricula2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                tituloMatricula={evento.item.tituloMatricula}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_MATRICULA_DATA_NASCIMENTO:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <MatriculaDataNascimento2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                tituloMatricula={evento.item.tituloMatricula}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_CPF:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <Cpf2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_VALIDA_CPF_QUESTIONARIO:
        tipoAutenticacao = (
          <Questionario
            handlerCadastrar={this.handleNaoEncontrado}
            onLogin={this.login}
            render={(handleOpenQuestionario) => (
              <LoginForm
                className={className}
                openQuestionario={handleOpenQuestionario}
                cadastrarClick={this.openIncluirParticipante}
                onLogin={this.login}
                onNaoEncontrado={this.handleNaoEncontrado}
                render={(comp) => (<Cpf handleFormChange={comp} />)}
              />
            )}
          />
        )
        break;
      case TIPO_AUTENTICACAO_2FA_VALIDA_MATRICULA:
        tipoAutenticacao = (
          <LoginForm
            className={className}
            cadastrarClick={this.openIncluirParticipante}
            onLogin={this.login}
            onNaoEncontrado={this.handleNaoEncontrado}
            render={(comp, handleFechou2fa, abre2fa, handleNaoEncontrado) => (
              <Matricula2fa
                handleFormChange={comp}
                handleFechou={handleFechou2fa}
                abre={abre2fa}
                onLogin={this.login}
                handleNaoEncontrado={handleNaoEncontrado}
                tituloMatricula={evento.item.tituloMatricula}
                incluirParticipanteHandle={this.openIncluirParticipante}
              />
            )}
          />
        )
        break;
      default:
        break;
    }

    return tipoAutenticacao;
  }

  render = () => {
    const {
      evento,
      className
    } = this.props;
    if ((Object.keys(evento.item).length > 0) && (!evento.item.ativo)) {
      return <EventoInativoPainel className={className} />
    }
    const tipoAutenticacao = this.getTipoAutenticacaoForm();

    if (this.state.redirectWithBase) {
      return <RedirectPanagora push to={this.state.redirectWithBase} withBase />
    }

  
    if (this.state.redirectPanagora) {
      return <RedirectPanagora push to={this.state.redirectPanagora} />
    }

    return (
      <>
        <LinkUnico onLogin={this.login}>
          {tipoAutenticacao}
        </LinkUnico>
        <PerguntaNaoEncontradoModal
          texto={this.state.textoPergunta}
          show={this.state.perguntaNaoEncontradoShow}
          onSimClick={this.handleSimPerguntaNaoEncontrado}
          onHide={this.handlePerguntaNaoEncontradoClose} />

        <FormIncluirParticipanteModal
          show={this.state.formIncluirParticipanteShow}
          onLogin={this.login}
          onHide={this.handleIncluirParticipanteClose}
          evento={this.props.evento.item}
          incluirParticipanteHandle={this.openIncluirParticipante}
        />
      </>
    );

  }
}

const mapStateToProps = store => ({
  evento: store.eventoState.evento
});


const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser, logoutUser }, dispatch);


export const userIsAuthenticated = (child) => {



  return connectedRouterRedirect({
    redirectPath: getBase() + "/auth",
    authenticatedSelector: state => state.userState.user !== null,
    wrapperDisplayName: 'UserIsAuthenticated',
  })(child);
}




export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
