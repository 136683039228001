import React from 'react';
import DataNascimento from './form-elements/DataNascimento';
import moment from 'moment';
import Matricula from './form-elements/Matricula';

class MatriculaDataNascimento extends React.Component {
  state = {
    form: {matriculaFuncional: '', dataNascimento: ''}
  }

  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    await this.setState({form: {...this.state.form, [fieldName]: fleldVal}})

    let dataNascimento = null;
    if (moment(this.state.form.dataNascimento,'DD/MM/YYYY',true).isValid()) {
      dataNascimento = moment(this.state.form.dataNascimento,'DD/MM/YYYY').format('YYYY-MM-DD');
    }  

    this.props.handleFormChange(
      {
        valid:this.valid()
      },
      {
        matriculaFuncional: this.state.form.matriculaFuncional,
        dataNascimento: dataNascimento
      });
  }

  valid = () => {
    return (this.dataNascimento.valid());
  }

  render() {
    return (
      <>
        <Matricula
          tituloMatricula={this.props.tituloMatricula}
          value={this.state.form.matriculaFuncional}
          onChange={this.handleChange}
          ref={ref => this.matriculaFuncional = ref}
          required
        />

        <DataNascimento
          value={this.state.form.dataNascimento}
          onChange={this.handleChange}
          ref={(ref)=> this.dataNascimento = ref}
          required
        />
      </>
    );
  }
}

export default MatriculaDataNascimento;
